<template>
  <div>
    <h1>杭州润达科技信息有限公司</h1>
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in banners" :key="index">
          <div :style="{ 'background-image': 'url(' + item.url + ')' }" class="swiper"></div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="menu">
      <ul>
        <li v-for="(item,index) in menulist" :key="index" @click="handleClick(item, index)">
          <div class="imgBox"><img :src="item.url" alt=""></div>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'
const  router = useRouter()
const banners = [
  {
    title: "banner1",
    url: require("../assets/img/banner1.jpg")
  },
  {
    title: "banner2",
    url: require("../assets/img/banner2.jpg")
  },
  {
    title: "banner3",
    url: require("../assets/img/banner3.jpg")
  },
]
const menulist = [
  {
    name: "官方首页",
    path: "/",
    url:require('../assets/img/menu1.png')
  },
  {
    name: "企业简介",
    path: "/introduction",
    url:require('../assets/img/menu2.png')
  },
  {
    name: "润达服务",
    path: "/service",
    url:require('../assets/img/menu3.png')
  },
  {
    name: "润达案例",
    path: "/case",
    url:require('../assets/img/menu6.png')
  },
  {
    name: "关于我们",
    path: "/aboutUs",
    url:require('../assets/img/menu4.png')
  },
  {
    name: "联系我们",
    path: "/contactUs",
    url:require('../assets/img/menu5.png')
  }
]
const handleClick = (item,index)=>{
  router.push({ path: item.path });
}
</script>
<style lang="less" scoped>
.banner{
  margin-bottom: 60px;
}
.swiper{
  height: 448px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
h1{
  font-size: 36px;
  color: #fff;
  line-height: 120px;
  height: 120px;
  position: absolute;
  top:0;left:0;z-index: 1;
  width: 100%;
}
.banner{
  height: 448px;
}
.menu{
  margin-bottom: 53px;
  padding: 0 30px;
  ul{
    display: flex;
    justify-content: space-between;
  }
  .imgBox{
    height: 80px;
    img{
      height: 100%;
    }
  }
  p{
    font-size: 24px;
    line-height: 34px;
  }
}

</style>
