<template>
  <div id="nav">
    <Header/>
  </div>
  <div class="layout-content">
    <router-view/>
  </div>
</template>
<script setup>
import{ onMounted} from 'vue'
import Header from '@/components/Header.vue'
onMounted(()=>{
  let hostname = window.location.href;
  if(!isMobile()){
  if (hostname) {
    if (hostname.indexOf("h.rundakj.com") > -1) {
        window.location.href = "http://www.rundakj.com"; 
      }
    }
  }
})
const isMobile = ()=>{
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 1.2rem;
}
.layout-content{
  padding:0 30px;
}
#nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
