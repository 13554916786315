import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('../views/introduction.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/index.vue'),
    redirect:'/case/list',
    children:[
      {
        path:'list',
        component: () => import('../views/case/case.vue'),
      },
      {
        path:'detail',
        component: () => import('../views/case/detail.vue'),
      },
    ]
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
